module.exports = {
    datePicker: {
        startDate: 'Start date',
        endDate: 'End date',
        placeholder: 'Select date',
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        weeks: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        year: '',
        month: '',
        day: '',
        today: 'Today',
        now: 'Now',
        ok: 'Ok',
        selectDate: 'Select date',
        selectTime: 'Select time',
        back: 'Back'
    },
    drawer: {
        ok: 'Ok',
        cancel: 'Cancel'
    },
    empty: {
        description: 'No Data'
    },
    pop: {
        ok: 'Ok',
        cancel: 'Cancel'
    },
    modal: {
        ok: 'Ok',
        cancel: 'Cancel'
    },
    page: {
        page: '',
        pageSize: '/page',
        goto: 'Go to',
        items: 'items',
        total: 'Total'
    },
    select: {
        placeholder: 'Select',
        loading: 'Loading...',
        emptyText: 'No Data'
    },
    table: {
        emptyText: 'No Data'
    },
    upload: {
        successful: 'Successful',
        failed: 'Failed',
        errorFileSize: 'Illegal file size'
    },
    Router: {
        Index: 'Index',
        Base: "Base",
        ButtonUi: 'ButtonUi',
        IconUi: 'IconUi',
        Layout: 'Layout',
        DividerUi: 'DividerUi',
        LayoutUi: 'LayoutUi',
        SpaceUi: 'SpaceUi',
        FlexUi: 'FlexUi',
        Navigation: 'Navigation',
        AffixUi: 'AffixUi',
        BreadcrumbUi: 'BreadcrumbUi',
        DropdownUi: 'DropdownUi',
        MenuUi: 'MenuUi',
        PageUi: 'PageUi',
        TabsUi: 'TabsUi',
        Form: 'Form',
        CheckboxUi: 'CheckboxUi',
        RadioUi: 'RadioUi',
        SwitchUi: 'SwitchUi',
        SelectUi: 'SelectUi',
        DatePickerUi: 'DatePickerUi',
        UploadUi: 'UploadUi',
        SliderUi: 'SliderUi',
        FormUi: 'FormUi',
        InputUi: 'InputUi',
        InputNumberUi: 'InputNumberUi',
        Data: 'Data',
        AvatarUi: 'AvatarUi',
        CardUi: 'CardUi',
        CarouselUi: 'CarousekUi',
        CollapseUi: 'CollapseUi',
        DescriptionsUi: 'DescriptionsUi',
        ImageUi: 'ImageUi',
        TableUi: 'TbleUi',
        TimeLineUi: 'TimeLineUi',
        TreeUi: 'TreeUi',
        Feedback: 'Feedback',
        AlertUi: 'AlerUi',
        BadgeUi: 'BadgeUi',
        DrawerUi: 'DrawerUi',
        EmptyUi: 'EmptyUi',
        MessageUi: 'MessageUi',
        NoticeUi: 'NoticeUi',
        ModalUi: 'ModalUi',
        PoptipUi: 'PoptipUi',
        PopconfirmUi: 'PopconfirmUi',
        RateUi: 'RateUi',
        SkeletonUi: 'SkeletonUi',
        SpinUi: 'SpinUi',
        ProgressUi: 'ProgressUi',
        LoadingUi: 'LoadingUi',
        TagUi: 'TagUi',
        TooltipUi: 'TooltipUi',
        Other: 'Other',
        ColorPickerUi: ' ColorPickerUi',
        PublicUi: 'PublicUi',
        PublicIndexUi: 'PublicIndexUi',
        Orange:'Orange',
    },
    Language: {
        language: 'Language',
        znch: 'Chinese',
        enus: "English",
    },
    Theme: {
        light: 'LightTheme',
        dark: 'DarkTheme',
        theme: 'Theme',
    },
    Card: {
        BaseButton: 'BaseButton',
        BorderButton: 'BorderButton',
        LightButton: 'LightButton',
        BorderlessNoBackgroundButton: 'BorderlessNoBackgroundButton',
        IconButton: 'IconButton',
        DisableButton: 'DisableButtom',
        LoadingButton: 'LoadingButtom',
        ButtonSize: 'ButtomSize',
        NoInformationTemporarily: 'NoInformationTemporarily',
    },
}
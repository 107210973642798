module.exports = {
  datePicker: {
    startDate: '开始日期',
    endDate: '结束日期',
    placeholder: '请选择日期',
    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    weeks: ['一', '二', '三', '四', '五', '六', '日'],
    year: '年',
    month: '月',
    day: '日',
    today: '今天',
    now: '此刻',
    ok: '确定',
    selectDate: '选择日期',
    selectTime: '选择时间',
    back: '返回'
  },
  drawer: {
    ok: '确定',
    cancel: '取消'
  },
  empty: {
    description: '暂无数据',
  },
  pop: {
    ok: '确定',
    cancel: '取消'
  },
  modal: {
    ok: '确定',
    cancel: '取消'
  },
  page: {
    page: '页',
    pageSize: '条/页',
    goto: '跳至',
    items: '条数据',
    total: '共'
  },
  select: {
    placeholder: '请选择',
    loading: '加载中...',
    emptyText: '暂无数据'
  },
  table: {
    emptyText: '暂无数据'
  },
  upload: {
    successful: '上传成功',
    failed: '上传失败',
    errorFileSize: '文件尺寸不合法'
  },
  Router: {
    Index:'首页',
    Base: "基础組件",
    ButtonUi: '按鈕',
    IconUi: '图标',
    Layout: '布局组件',
    DividerUi: '分割线',
    LayoutUi: '布局',
    SpaceUi: '间距',
    FlexUi: '栅格',
    Navigation: '导航组件',
    AffixUi: '图钉',
    BreadcrumbUi: '面包屑导航',
    DropdownUi: '下拉菜单',
    MenuUi: '导航菜单',
    PageUi: '分页',
    TabsUi: '标签页',
    Form: '表单组件',
    CheckboxUi: '多选框',
    RadioUi: '单选框',
    SwitchUi: '开关',
    SelectUi: '选择器',
    DatePickerUi: '日期选择器',
    UploadUi: '上传',
    SliderUi: '滑动输入条',
    FormUi: '表单',
    InputUi: '输入框',
    InputNumberUi: '数字输入框',
    Data: '數據展示',
    AvatarUi: '頭像',
    CardUi: '卡片',
    CarouselUi: '輪播',
    CollapseUi: '折疊面板',
    DescriptionsUi: '描述列表',
    ImageUi: '圖片',
    TableUi: '表格',
    TimeLineUi: '時間線',
    TreeUi: '樹形菜單',
    Feedback: '信息反饋',
    AlertUi: '警告提示',
    BadgeUi: '徽標',
    DrawerUi: '抽屜',
    EmptyUi: '空狀態',
    MessageUi: '全局提示',
    NoticeUi: '通知提示',
    ModalUi: '模態框',
    PoptipUi: '氣泡卡片',
    PopconfirmUi: '氣泡確認框',
    RateUi: '評分',
    SkeletonUi: '骨架屏',
    SpinUi: '加载中',
    ProgressUi: '进度条',
    LoadingUi: '加载进度',
    TagUi: "标签",
    TooltipUi:'文字提示',
    Other:'其他',
    ColorPickerUi:'颜色选择器',
    PublicUi:'全體路由',
    PublicIndexUi:'內容',
    Orange:'橙子',
  },
  Language: {
    language: '语言',
    znch: '中文',
    enus: "英语",
  },
  Theme: {
    light: '浅色主题',
    dark: '夜间主题',
    theme:'主題',
  },
  Card: {
    BaseButton: '基础按钮',
    BorderButton: '边框按钮',
    LightButton: '浅色按钮',
    BorderlessNoBackgroundButton: '无边框无背景按钮',
    IconButton: '图标按钮',
    DisableButton: '禁用按钮',
    LoadingButton: '加载按钮',
    ButtonSize: '按钮尺寸',
    NoInformationTemporarily: '暂无信息',
  },
}